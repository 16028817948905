import * as React from 'react';
import { Container } from 'reactstrap';
import NavMenu from './NavMenu';
import Header from './Header';
import { Footer } from './Footer';
import { ISI }  from './ISI';

export default (props: { children?: React.ReactNode }) => (
    <React.Fragment>
        <Header/>
        {props.children}
        <ISI />
    </React.Fragment>
);

