import * as React from 'react';


export class ISI extends React.PureComponent<any, any> {

    componentDidMount() {
        var divISI = document.getElementById('fixed-isi')
        if (window.location.pathname == '/') {
            if (divISI != null)
                divISI.style.top = "calc(100% - 245px)";
        }
        else {
            if (divISI != null) {
                divISI.style.top = "calc(100% - 32px)";
                divISI.classList.add('isi-top-inactive');
            }
        }
    }

    render() {
        return (
            <div>
                <div id="fixed-isi" className="isi idle loaded" onClick={this.handleToggle}>
                    <div className="banner">
                        <div className="container">
                            <div className="content">
                                <h2>INDICATION AND IMPORTANT SAFETY INFORMATION</h2>
                            </div>
                        </div>
                    </div>
                    <div className="container">
                        <div className="content">
                            <div className="must-be-visible">
                                <h3>INDICATION</h3>
                                <p>
                                    ORLADEYO<sup>&reg;</sup> (berotralstat) is a plasma kallikrein inhibitor indicated for prophylaxis to prevent attacks of
                                    hereditary angioedema (HAE) in adults and pediatric patients 12 years and older.
    </p>
                                <p>
                                    <strong>Limitations of use</strong><br />
                                    The safety and effectiveness of ORLADEYO for the treatment of acute HAE attacks have not been established.
                                    ORLADEYO should not be used for the treatment of acute HAE attacks. Additional doses or dosages of ORLADEYO
                                    higher than 150 mg once daily are not recommended due to the potential for QT prolongation.
    </p>
                                <h3>IMPORTANT SAFETY INFORMATION</h3>
                                <p className="mb-0">
                                    An increase in QT prolongation was observed at dosages higher than the recommended 150 mg once-daily dosage and was
                                    concentration dependent.
    </p>

                                <p>
                                    The most common adverse reactions (≥10% and higher than placebo) in patients receiving ORLADEYO were abdominal pain,
                                    vomiting, diarrhea, back pain, and gastroesophageal reflux disease.
</p>
                                <p>
                                    A reduced dosage of 110 mg taken orally once daily with food is recommended in patients with moderate or severe hepatic impairment (Child-Pugh B or C).
</p>
                                <p>
                                    Berotralstat is a substrate of P-glycoprotein (P-gp) and breast cancer resistance protein. P-gp inducers (eg, rifampin, St. John’s wort) may decrease berotralstat plasma concentration, leading to reduced efficacy of ORLADEYO. The use of P-gp inducers is not recommended with ORLADEYO.
</p>
                                <p>
                                    ORLADEYO at a dose of 150 mg is a moderate inhibitor of CYP2D6 and CYP3A4. For concomitant medications with a narrow
                                    therapeutic index that are predominantly metabolized by CYP2D6 or CYP3A4, appropriate monitoring and dose titration
                                    is recommended. ORLADEYO at a dose of 300 mg is a P-gp inhibitor. Appropriate monitoring and dose titration is recommended for P-gp substrates (eg, digoxin) when coadministering with ORLADEYO.
</p>
                                <p>
                                    The safety and effectiveness of ORLADEYO in pediatric patients &lt;12 years of age have not been established.
</p>
                                <p>
                                    There are insufficient data available to inform drug-related risks with ORLADEYO use in pregnancy. There are no data
                                    on the presence of berotralstat in human milk, its effects on the breastfed infant, or its effects on milk
                                    production.
</p>
                                <p>
                                    <strong>
                                        To report SUSPECTED ADVERSE REACTIONS, contact BioCryst Pharmaceuticals, Inc. at 1-833-633-2279 or FDA at 1-800-FDA-1088 or <a href="https://www.fda.gov/safety/medwatch-fda-safety-information-and-adverse-event-reporting-program" rel="noopener" target="_blank">www.fda.gov/medwatch</a>.
    </strong>
                                </p>
                                <p>
                                    <strong>Please see accompanying </strong>
                                    <a target="_blank" href="https://www.biocryst.com/wp-content/uploads/ORLADEYO_USPI.pdf">
                                        <strong>full Prescribing Information</strong></a><strong>.</strong>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        );
    }

    handleToggle = () => {
        var divISI = document.getElementById('fixed-isi')
        if (divISI != null) {
            if (divISI.classList.contains('isi-top-active')) {
                divISI.classList.remove('isi-top-active');
                divISI.classList.remove('active');
                divISI.classList.add('isi-top-inactive');
            }
            else {
                divISI.classList.add('isi-top-active');
                divISI.classList.add('active');
                divISI.classList.remove('isi-top-inactive');
            }
        }
        var divregisterContainer = document.getElementById('registerContainer');
        if (divregisterContainer != null) {
            divregisterContainer.style.height = 'auto';
        }
        //Home page height
        var divHomeHero = document.getElementById('home-hero')
        if (divHomeHero != null) {
            divHomeHero.style.height = "auto";
            divHomeHero.style.paddingBottom = '0px';
        }
        //Confirmation page height
        var divConfirmationContainer = document.getElementById('divConfirmationContainer')
        if (divConfirmationContainer != null) {
            divConfirmationContainer.style.height = "auto";
        }

    }

};
